<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div class="uk-form-stacked">
        <h4 class="uk-heading-line">
          Facility Info
        </h4>
        <div v-if="!loadingData">
          <alert-status
            v-if="listExportHub.data.length < 1"
            status="warning"
            label="Must add Export Hub first if you want to add this facility."
          />
          <alert-status
            v-if="exportHubId && listHulling.data.length < 1"
            status="warning"
            label="Must add Hulling Facility first if you want to add this facility."
          />
          <alert-status
            v-if="listExportHub.data.length < 1 && listHulling.data.length < 1"
            status="warning"
            label="Must add Export Hub and add Hulling Facility first if you want to add this facility."
          />
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  VCP Code
                </label>
                <div class="uk-form-controls uk-flex">
                  <div class="uk-width-1-2 uk-margin-right">
                    <input
                      :value="vc_code"
                      name="vc_code"
                      class="uk-input"
                      type="text"
                      placeholder="VC Code"
                      disabled
                    >
                  </div>
                  <div class="uk-width-1-2">
                    <input
                      v-model="formPulping.vcp_code"
                      v-validate="'required|max:50'"
                      name="vcp_code"
                      class="uk-input"
                      type="text"
                      autocomplete="off"
                      placeholder="Enter VCP Code"
                      :disabled="export_hub == '' || hulling_facility == ''"
                      :class="{'uk-form-danger': errors.has('vcp_code')}"
                    >
                    <span
                      v-show="errors.has('vcp_code')"
                      class="uk-text-small uk-text-danger"
                    >{{ errors.first('vcp_code') }}</span>
                  </div>
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Export Hub
                </label>
                <div class="uk-form-controls">
                  <multiselect 
                    v-model="export_hub" 
                    v-validate="'required'"
                    label="name" 
                    name="export_hub" 
                    track-by="id" 
                    placeholder="Select Export Hub or Type to Search" 
                    open-direction="bottom" 
                    :options="listExportHub.data"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                    @search-change="(input) => handleSearchDropdown('export', listExportHub.data, input)"
                    @remove="() => recallApiList('export')"
                    @select="() => recallApiList('export')"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
                <span
                  v-show="errors.has('export_hub')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('export_hub') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Latitude
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPulping.latitude"
                    v-validate="'required|max:100'"
                    name="latitude"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Latitude"
                    :class="{'uk-form-danger': errors.has('latitude')}"
                  >
                </div>
                <span
                  v-show="errors.has('latitude')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('latitude') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Address
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPulping.address"
                    v-validate="'required|max:100'"
                    name="address"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Address"
                    :class="{'uk-form-danger': errors.has('address')}"
                  >
                </div>
                <span
                  v-show="errors.has('address')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('address') }}</span>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Facility Name
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPulping.facility_name"
                    v-validate="'required|max:50'"
                    name="facility_name"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Facility Name"
                    :class="{'uk-form-danger': errors.has('facility_name')}"
                  >
                </div>
                <span
                  v-show="errors.has('facility_name')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('facility_name') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Hulling Facility
                </label>
                <div class="uk-form-controls">
                  <multiselect 
                    v-model="hulling_facility"
                    v-validate="'required'"
                    label="facility_name"
                    name="hulling_facility"
                    track-by="id"
                    :placeholder="export_hub ? 'Select Hulling Facility or Type to Search' : 'Select Export Hub First'"
                    open-direction="bottom"
                    :options="listHulling.data"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                    :disabled="!export_hub"
                    @search-change="(input) => handleSearchDropdown('hulling', listHulling.data, input)"
                    @remove="() => recallApiList('hulling')"
                    @select="() => recallApiList('hulling')"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
                <span
                  v-show="errors.has('hulling_facility')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('hulling_facility') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Longitude
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPulping.longitude"
                    v-validate="'required|max:100'"
                    name="longitude"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Longitude"
                    :class="{'uk-form-danger': errors.has('longitude')}"
                  >
                </div>
                <span
                  v-show="errors.has('longitude')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('longitude') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        Lapang Jemur
      </h4>
      <div class="uk-form-stacked form-dry-fields">
        <div
          v-for="(data, index) in drying_field_list"
          :key="index"
          class="uk-child-width-expand@s uk-grid-small" 
          uk-grid
        >
          <div class="uk-width-1-2">
            <div class="uk-form-controls uk-position-relative withlabel">
              <label class="uk-form-label">
                Type {{ index + 1 }}
              </label>
              <select
                v-model="data.drying_field_type_id"
                class="uk-width-expand custom-select"
                name="drying_field_type_id"
              >
                <option
                  value=""
                  hidden
                >
                  Select Type
                </option>
                <option
                  v-for="(item, key) in listDryingField"
                  :key="key"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <img
                :src="`${images}/icon/ic_chevron_down.svg`"
                alt="ic_chevron"
              >
            </div>
            <span
              v-show="errors.has(`drying_field_type_${index + 1}`)"
              class="uk-text-small uk-text-danger"
            >{{ errors.first(`drying_field_type_${index + 1}`) }}</span>
          </div>

          <div class="uk-width-1-2">
            <label class="uk-form-label">
              Name {{ index + 1 }}
            </label>
            <div class="uk-form-controls">
              <input
                v-model="data.drying_field_name"
                :name="`drying_field_name_${index + 1}`"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Lapang Jemur Name"
                :class="{'uk-form-danger': errors.has(`drying_field_name_${index + 1}`)}"
              >
              <span
                v-show="errors.has(`drying_field_name_${index + 1}`)"
                class="uk-text-small uk-text-danger"
              >{{ errors.first(`drying_field_name_${index + 1}`) }}</span>
            </div>
          </div>

          <div
            class="img-fields"
            :hidden="index > 0 ? false : true"
          >
            <img
              :src="`${images}/icon/ic_delete_table.svg`"
              alt=""
              @click.prevent="handleDeleteFormDrying()"
            >
          </div>
        </div>
      </div>
      
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
        uk-grid
      >
        <button
          class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
          style="justify-content: flex-start;"
          @click.prevent="handleCreateFormDrying()"
        >
          <img
            :src="`${images}/icon/ic_plus_green.svg`"
            class="uk-margin-small-right cursor-pointer"
          >
          Add Lapang Jemur
        </button>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        Info Bak
      </h4>
      <div class="uk-form-stacked form-bucket-list">
        <div
          v-for="(data, index) in bucket_list"
          :key="index"
          class="uk-child-width-expand@s uk-grid-small" 
          uk-grid
        >
          <div class="uk-width-1-1">
            <label class="uk-form-label">
              Bak Name {{ index + 1 }}
            </label>
            <div class="uk-form-controls">
              <input
                v-model="data.bucket_name"
                :name="`bucket_name_${index + 1}`"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Bak Name"
                :class="{'uk-form-danger': errors.has(`bucket_name_${index + 1}`)}"
              >
              <span
                v-show="errors.has(`bucket_name_${index + 1}`)"
                class="uk-text-small uk-text-danger"
              >{{ errors.first(`bucket_name_${index + 1}`) }}</span>
            </div>
          </div>

          <div
            class="uk-width-auto img-fields"
            :hidden="index > 0 ? false : true"
          >
            <img
              :src="`${images}/icon/ic_delete_table.svg`"
              alt=""
              @click.prevent="handleDeleteFormBucket()"
            >
          </div>
        </div>
      </div>
      
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
        uk-grid
      >
        <button
          class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
          style="justify-content: flex-start;"
          @click.prevent="handleCreateFormBucket()"
        >
          <img
            :src="`${images}/icon/ic_plus_green.svg`"
            class="uk-margin-small-right cursor-pointer"
          >
          Add Bak
        </button>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        App Access
      </h4>
      <div class="uk-margin-top uk-child-width-expand@s">
        <div class="uk-overflow-auto border-table">
          <table
            class="uk-table uk-table-divider uk-table-middle"
            aria-describedby="hulling-facility-table"
          >
            <thead class="thead-table-varion">
              <tr>
                <th class="uk-table-expand">
                  Menu Name
                </th>
                <th class="uk-width-small" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Terima Gabah</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formPulping.recipient"
                      @change="handleChangeRecipient"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Sortasi</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formPulping.sorter"
                      @change="handleChangeSorter"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Pengupasan</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formPulping.peeler"
                      @change="handleChangePeeler"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Fermentasi</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formPulping.fermenter"
                      @change="handleChangeFermenter"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Pencucian</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formPulping.washer"
                      @change="handleChangeWasher"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Pengeringan</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formPulping.dryer"
                      @change="handleChangeDryer"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>

              <tr>
                <td>Pengepakan</td>
                <td>
                  <label class="switch">
                    <input
                      class="switcher"
                      type="checkbox"
                      :checked="formPulping.courier"
                      @change="handleChangeCourier"
                    >
                    <span class="slider round" />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
                
      <div class="uk-flex uk-flex-right uk-margin-medium-top">
        <button
          class="uk-button soft-green uk-margin-right"
          @click="showCancelConfirmModal"
        >
          Cancel
        </button>
        <button
          class="uk-button green"
          :class="isCanAccessUser('add', 'PULPING FACILITY') ? '' : 'disabled'"
          :disabled="!isCanAccessUser('add', 'PULPING FACILITY')"
          @click="showSaveConfirmModal"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AlertStatus from '@/components/globals/AlertStatus'
import { isCanAccess } from '@/utils/auth'
import { mapActions, mapGetters } from 'vuex'


export default {
  components: {
    AlertStatus
  },
  props: {
    images: {
      required: true,
      type: String
    },
    loadingData:{
      type: Boolean
    },
    listDryingField: {
      required: true,
      type: Array
    },
    listExportHub: {
      required: true,
      type: Object
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  data() {
    return{
      vc_code: '',
      export_hub_code: '',
      hulling_facility_code: '',
      export_hub: '',
      hulling_facility: '',
      drying_field_list: [
        { 
          drying_field_type_id: '',
          drying_field_name: ''
        }
      ],
      bucket_list: [
        {
          bucket_name: ''
        }
      ],
      searchOn: false,
      formPulping: {
        vcp_code: '',
        facility_name:'',
        latitude: '',
        longitude: '',
        export_hub_id: '',
        hulling_facility_id: '',
        address: '',
        drying_field_list: [],
        bucket_list: [],
        recipient: true,
        sorter: true,
        peeler: true,
        fermenter: true,
        washer: true,
        dryer: true,
        courier: true
      },
      exportHubId: ''
    }
  },
  computed: {
    ...mapGetters({
      listHulling: 'hullingFacility/list_hulling'
    })
  },
  watch: {
    export_hub(val) {
      if(val) {
        this.hulling_facility = null
        this.exportHubId = val.id
        this.export_hub_code = this.export_hub?.evc_code
        if(this.hulling_facility == null) {
          this.vc_code = `${this.export_hub_code}`
        } else {
          this.vc_code = `${this.export_hub_code}-${this.hulling_facility_code}`
          this.formPulping.vcp_code = 'P'
        }
      }
    },
    hulling_facility(val) {
      if(val){
        this.hulling_facility_code = this.hulling_facility?.vc_code.split('-')[1]
        if(this.export_hub == '') {
          this.vc_code = `${this.hulling_facility_code}`
        } else {
          this.vc_code = `${this.export_hub_code}-${this.hulling_facility_code}`
          this.formPulping.vcp_code = 'P'
        }
      }
    },
    async exportHubId(){
      await this.actionGetListHulling({ export_hub_id: this.exportHubId, page: 1, limit: 50 })
    }
  },
  methods: {
    ...mapActions({
      actionGetListHulling: 'hullingFacility/getListHulling',
      actionGetListExportHub: 'exportHub/getListExportHub'
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    async handleSearchDropdown(list, data, input) {
      const dataList = data
  
      const checker = dataList.some((item) => {
        const lowerFacilityName = item.facility_name ? item.facility_name.toLowerCase() : item.name.toLowerCase()
        const lowerSearchQuery = input.toLowerCase()
        return lowerFacilityName.includes(lowerSearchQuery)
      })

      if (!checker && !(dataList.length < 1)) {
        this.searchOn = true
        switch (list) {
        case 'hulling':
          await this.actionGetListHulling({ export_hub_id: this.exportHubId, search: input })
          break
        default:
          await this.actionGetListExportHub({ search: input })
          break
        }
      }

      if (!input) {
        let i = input
        if(!(i.includes(input)) || dataList.length < 1) {
          switch (list) {
          case 'hulling':
            await this.actionGetListHulling({ export_hub_id: this.exportHubId, page: 1, limit: 50 })
            break
          default:
            await this.actionGetListExportHub({ page: 1, limit: 50 })
            break
          }
        }
      }
    },
    async recallApiList(list){
      if(this.searchOn){
        this.searchOn = false
        switch (list) {
        case 'hulling':
          await this.actionGetListHulling({ export_hub_id: this.exportHubId, page: 1, limit: 50 })
          break
        default:
          await this.actionGetListExportHub({ page: 1, limit: 50 })
          break
        }
      }
    },
    handleCreateFormDrying() {
      this.drying_field_list.push({
        drying_field_type_id: '',
        drying_field_name: ''
      })
    },
    handleDeleteFormDrying() {
      this.drying_field_list.splice(this.drying_field_list.length - 1, 1)
    },
    handleCreateFormBucket() {
      this.bucket_list.push({
        bucket_name: ''
      })
    },
    handleDeleteFormBucket() {
      this.bucket_list.splice(this.bucket_list.length - 1, 1)
    },
    handleChangeRecipient(event) {
      this.formPulping.recipient = event.target.checked ? true : false
    },
    handleChangeSorter(event) {
      this.formPulping.sorter = event.target.checked ? true : false
    },
    handleChangePeeler(event) {
      this.formPulping.peeler = event.target.checked ? true : false
    },
    handleChangeFermenter(event) {
      this.formPulping.fermenter = event.target.checked ? true : false
    },
    handleChangeWasher(event) {
      this.formPulping.washer = event.target.checked ? true : false
    },
    handleChangeDryer(event) {
      this.formPulping.dryer = event.target.checked ? true : false
    },
    handleChangeCourier(event) {
      this.formPulping.courier = event.target.checked ? true : false
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'PulpingFacility',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      this.formPulping.export_hub_id = this.export_hub ? this.export_hub?.id : ''
      this.formPulping.hulling_facility_id = this.hulling_facility ? this.hulling_facility?.id : ''
      let tempDrying = []
      let tempBucket = []
      this.drying_field_list.map(item => tempDrying.push({
        drying_field_type_id: item.drying_field_type_id,
        drying_field_name: item.drying_field_name
      }))
      this.bucket_list.map(item => tempBucket.push(item.bucket_name))
      this.formPulping.drying_field_list = tempDrying 
      this.formPulping.bucket_list = tempBucket
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formPulping)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .form-dry-fields .uk-width-1-2 {
    max-width: 48%;
  }
  .form-bucket-list .uk-width-1-1 {
    max-width: 96%;
  }
  .btn-add-create-form {
    font-family: 'interMedium';
    font-weight: 600;
    font-size: 16px;
    background: transparent;
    color: #2FAC4A;
  }
  .img-fields {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;
  }
  .uk-table thead tr {
    border: 1px solid #E0E0E0;
  }
  .uk-table tbody tr {
    border: 1px solid #E0E0E0;
  }
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
</style>
